import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
// import ReactSelect from '../selectElement/reactSelect';
import ReactSelect from "react-select";
import api from "../../../api";
import axios from "axios";

interface OwnACaskModalProps {
  openOwnCaskModal: boolean;
  // toggleOwnCaskModal: () => void;
  setOpenCaskThanksModal: (value: boolean) => boolean;
}

interface FormData {
  name: string;
  email: string;
  contactNo: string;
  caskType: { value: string; label: string } | null;
  bottlingType: { value: string; label: string } | null;
  bottleCount: { value: string; label: string } | null;
  launchCountry: { value: string; label: string } | null;
  region: { value: string; label: string } | null;
  message: string;
  emailMarketing: boolean;
  privacyPolicy: boolean;
  referrer: string;
}

interface FormErrors {
  name?: string;
  email?: string;
  contactNo?: string;
  caskType?: string;
  bottlingType?: string;
  bottleCount?: string;
  launchCountry?: string;
  region?: string;
  message?: string;
  emailMarketing?: string;
  privacyPolicy?: string;
}

function hasEmailError(jsonResponse: any) {
  try {
    const data = jsonResponse; // Parse the JSON string

    if (data.error && data.error.details && data.error.details.errors) {
      for (const error of data.error.details.errors) {
        if (error.path && error.path.includes("email")) {
          // Check if "email" is in the path array
          return true; // Email error found
        }
      }
    }
    return false; // No email error found
  } catch (error) {
    console.error("Error parsing JSON:", error);
    return false; // Handle parsing errors as no email error (or you could return true, depending on how you want to handle it)
  }
}

const OwnACask: React.FC<OwnACaskModalProps> = ({ openOwnCaskModal, setOpenCaskThanksModal }) => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    contactNo: "",
    caskType: null,
    bottlingType: null,
    bottleCount: null,
    launchCountry: null,
    region: null,
    message: "",
    emailMarketing: false,
    privacyPolicy: false,
    referrer: "",
  });

  const [errors, setErrors] = useState<FormErrors>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countryData, setCountryData]: any = useState([]);

  const caskTypeOptions = [
    { label: "Moscatel", value: "Moscatel" },
    { label: "Oloroso", value: "Oloroso" },
    { label: "Palo Cortado", value: "Palo Cortado" },
    { label: "Pedro Ximinex", value: "Pedro Ximinex" },
    { label: "No Preference", value: "No Preference" },
  ];

  const bottlingTypeOptions = [
    { label: "Corporate Gifting", value: "Corporate Gifting" },
    { label: "Other Spirits", value: "Other Spirits" },
    { label: "Private label", value: "Private label" },
    { label: "Private Occasion", value: "Private Occasion" },
  ];

  const bottleCountOptions = [
    { label: "80+", value: "80+" },
    { label: "150+", value: "150+" },
    { label: "250+", value: "250+" },
    { label: "500+", value: "500+" },
  ];

  const regionOptions = [
    { label: "Blended Whisky", value: "Blended Whisky" },
    { label: "Campbeltown", value: "Campbeltown" },
    { label: "Highland", value: "Highland" },
    { label: "Islay", value: "Islay" },
    { label: "Lowland", value: "Lowland" },
    { label: "Speyside", value: "Speyside" },
  ];

  const validateForm = (): FormErrors => {
    const newErrors: FormErrors = {};

    // Name validation
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    } else if (formData.name.length < 2) {
      newErrors.name = "Name must be at least 2 characters long";
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
    }

    // Contact number validation
    const phoneRegex = /^\+?[\d\s-]{8,}$/;
    if (!formData.contactNo) {
      newErrors.contactNo = "Contact number is required";
    } else if (!phoneRegex.test(formData.contactNo)) {
      newErrors.contactNo = "Please enter a valid contact number";
    }

    // Dropdown validations
    if (!formData.caskType) {
      newErrors.caskType = "Please select a cask type";
    }
    if (!formData.bottlingType) {
      newErrors.bottlingType = "Please select a bottling type";
    }
    if (!formData.bottleCount) {
      newErrors.bottleCount = "Please select number of bottles";
    }
    if (!formData.launchCountry) {
      newErrors.launchCountry = "Please select a launch country";
    }
    if (!formData.region) {
      newErrors.region = "Please select a region";
    }

    // message validation
    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
    } else if (formData.message.length < 10) {
      newErrors.message = "Message must be at least 10 characters long";
    }

    // Consent validations
    if (!formData.privacyPolicy) {
      newErrors.privacyPolicy = "You must agree to the Privacy Policy";
    }

    return newErrors;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    if (type === "checkbox") {
      const checkbox = e.target as HTMLInputElement;
      setFormData((prev) => ({
        ...prev,
        [name]: checkbox.checked,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    // Clear error when user starts typing
    if (errors[name as keyof FormErrors]) {
      setErrors((prev) => ({
        ...prev,
        [name]: undefined,
      }));
    }
  };

  const getCountryListData = async () => {
    const res = await api
      .getCountryList()
      .then((res: any) => res.data)
      .catch((err: any) => err.data);

    const countryData = res?.slice(1).map((country: any) => {
      return {
        label: country.attributes.name,
        value: country.attributes.locale,
      };
    });
    setCountryData(countryData);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors = validateForm();

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      setIsSubmitting(true);

      // Prepare the data for submission
      const submissionData = {
        ...formData,
        mobile: formData.contactNo,
        caskType: formData.caskType?.value,
        purpose: formData.bottlingType?.value,
        bottleCount: formData.bottleCount?.value,
        countryofResidence: formData.launchCountry?.value,
        whiskyRegion: formData.region?.value,
        referrer: "create-your-own",
      };

      let url = process.env.NEXT_PUBLIC_DUNCAN_TAYLOR_API_URL + "/api/octave-contactforms";

      await axios
        .post(url, {
          data: submissionData,
        })
        .then((res: any) => {
          if (res.status == 200) {
            setOpenCaskThanksModal(true);
          }
        });

      // Reset form on success
      setFormData({
        name: "",
        email: "",
        contactNo: "",
        caskType: null,
        bottlingType: null,
        bottleCount: null,
        launchCountry: null,
        region: null,
        message: "",
        emailMarketing: false,
        privacyPolicy: false,
        referrer: "",
      });

      // Close modal
      // toggleOwnCaskModal();
    } catch (error: any) {
      console.error("Error submitting form:", error);
      if (error && hasEmailError(error?.response?.data)) {
        setErrors((prev) => ({
          ...prev,
          email: error?.response?.data?.error?.message,
        }));
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    getCountryListData();
  }, []);

  const sortedOptions = countryData.sort((a: any, b: any) => a.label.localeCompare(b.label));

  return (
    <div className="own_a_cask_modal own_a_cask_page_div">
      <div className="new_cask_modal_section">
        <div className="cask_image_section">
          <img src="/new-octave-collection/own-octave-collection-modal-img.png" alt="cask img" width={470} height={734} />
        </div>
        <div className="cask_form_section">
          <div className="cask_form_inner_div">
            <div className="form_header">
              <div className="form_inner_div_title">
                <h2>Own an</h2>
                <h3>Octave Cask</h3>
              </div>
            </div>
            <form onSubmit={handleSubmit} className="form_of_own_cask">
              <div className="double_input_show">
                <div className="input-group">
                  <input
                    className={`own_cask_input ${errors.name ? "error" : ""}`}
                    type="text"
                    placeholder="Name"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    autoComplete="nope"
                  />
                  <div className="error-message">{errors?.name}</div>
                </div>

                <div className="input-group">
                  <input
                    className={`own_cask_input ${errors.email ? "error" : ""}`}
                    type="email"
                    placeholder="Email Address"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    autoComplete="nope"
                  />
                  <div className="error-message">{errors?.email}</div>
                </div>
              </div>

              <div className="double_input_show">
                <div className="input-group">
                  <input
                    className={`own_cask_input ${errors.contactNo ? "error" : ""}`}
                    type="text"
                    placeholder="Contact No."
                    id="contactNo"
                    name="contactNo"
                    value={formData.contactNo}
                    onChange={handleInputChange}
                    autoComplete="nope"
                  />
                  <div className="error-message">{errors?.contactNo}</div>
                </div>

                <div className="react_select_div">
                  <ReactSelect
                    value={formData.caskType}
                    onChange={(selected: any) => {
                      setFormData((prev) => ({ ...prev, caskType: selected }));
                      setErrors((prev) => ({ ...prev, caskType: undefined }));
                    }}
                    options={caskTypeOptions}
                    classNamePrefix="react-select"
                    placeholder="Cask Type"
                    id="cask-type"
                  />
                  <div className="error-message">{errors?.caskType}</div>
                </div>
              </div>

              <div className="two_select_section">
                <div className="react_select_div">
                  <ReactSelect
                    value={formData.bottlingType}
                    onChange={(selected: any) => {
                      setFormData((prev) => ({ ...prev, bottlingType: selected }));
                      setErrors((prev) => ({ ...prev, bottlingType: undefined }));
                    }}
                    options={bottlingTypeOptions}
                    classNamePrefix="react-select"
                    placeholder="Purpose"
                    id="bottling-type"
                  />
                  <div className="error-message">{errors.bottlingType}</div>
                </div>
                <div className="react_select_div">
                  <ReactSelect
                    value={formData.bottleCount}
                    onChange={(selected: any) => {
                      setFormData((prev) => ({ ...prev, bottleCount: selected }));
                      setErrors((prev) => ({ ...prev, bottleCount: undefined }));
                    }}
                    options={bottleCountOptions}
                    classNamePrefix="react-select"
                    placeholder="No. of Bottles"
                    id="bottle-count"
                  />
                  <div className="error-message">{errors?.bottleCount}</div>
                </div>
              </div>

              <div className="two_select_section">
                <div className="react_select_div">
                  <ReactSelect
                    value={formData.launchCountry}
                    onChange={(selected: any) => {
                      setFormData((prev) => ({ ...prev, launchCountry: selected }));
                      setErrors((prev) => ({ ...prev, launchCountry: undefined }));
                    }}
                    options={sortedOptions}
                    classNamePrefix="react-select"
                    placeholder="Country of Residence"
                    id="launch-country"
                  />
                  <div className="error-message">{errors?.launchCountry}</div>
                </div>
                <div className="react_select_div">
                  <ReactSelect
                    value={formData.region}
                    onChange={(selected: any) => {
                      setFormData((prev) => ({ ...prev, region: selected }));
                      setErrors((prev) => ({ ...prev, region: undefined }));
                    }}
                    options={regionOptions}
                    classNamePrefix="react-select"
                    placeholder="Whisky Region"
                    id="region"
                  />
                  <div className="error-message">{errors?.region}</div>
                </div>
              </div>

              <textarea
                className="own_cask_textarea"
                placeholder="Leave us a message"
                style={{ resize: "none" }}
                name="message"
                value={formData.message}
                onChange={handleInputChange}
              />
              <div className="error-message">{errors?.message}</div>

              <div className="check_box_for_modal">
                <input
                  type="checkbox"
                  id="marketing-consent"
                  name="emailMarketing"
                  className="input_check_box"
                  checked={formData.emailMarketing}
                  onChange={handleInputChange}
                />
                <h3>
                  I agree to receive marketing mailers, exclusive updates, and special offers. You can unsubscribe at any time. View our Privacy
                  Policy for more details.
                </h3>
              </div>

              <div className="check_box_for_modal">
                <input
                  type="checkbox"
                  id="privacy-consent"
                  name="privacyPolicy"
                  className="input_check_box"
                  checked={formData.privacyPolicy}
                  onChange={handleInputChange}
                />
                <h3>By ticking this box I agree that I have read the Privacy Policy</h3>
              </div>
              <div className="error-message">{errors?.privacyPolicy}</div>

              <button className="btn_cask_submit" type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OwnACask;
