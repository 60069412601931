"use client";
import MainLayout from "@/components/layout/mainLayout";
import { useEffect, useState } from "react";
import BuyNowCart from "@/components/common/BuyNowCart";
import NewCollectionBanner from "@/components/newoctavecollection/NewCollectionBanner";
import { getCheckoutLineItems, setRemoveLineItemInCart, setUpdateQuantityInCart } from "@/util/utils";
import ScoMeta from "@/components/scometa";
import OwnACaskModal from "@/components/newoctavecollection/OwnACaskModal";
import ThanksCaskModal from "@/components/newoctavecollection/ThanksCaskModal";
import OwnACask from "@/components/newoctavecollection/OwnACask";

const CreateYourOwn = (props: any) => {
  const { data, meta, countryCode } = props;

  const [octavecollectionData, setOctavecollectionData]: any = useState(null);
  const [checkout, setCheckout] = useState({ checkout: { lineItems: [] } });
  const [openModal, setOpenModal]: any = useState(false);
  const [openOwnCaskModal, setOpenOwnCaskModal]: any = useState(false);
  const [openCaskThanksModal, setOpenCaskThanksModal]: any = useState(false);

  useEffect(() => {
    if (data) {
      setOctavecollectionData(data?.data?.attributes?.blocks);
    }
  }, [data]);
  const renderOctaveCollectionPage = () => {
    return (
      <>
        {octavecollectionData && octavecollectionData.length
          ? octavecollectionData.map((content: any, index: any) => {
            switch (content.__component) {
              case "sections.banner":
                return (
                  <div key={index}>
                    <NewCollectionBanner data={content} />
                  </div>
                );

              // case "octave.about-the-collection":
              //   return <OctaveDetailsSection key={content?.id + index} data={content} />;

              default:
                return null;
            }
          })
          : null}
      </>
    );
  };

  const toggleCartSidebar = () => {
    getCheckoutLineItems(countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });

    setOpenModal(!openModal);
  };

  useEffect(() => {
    getCheckoutLineItems(countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  }, []);

  const updateQuantityInCart = (lineItemId: any, quantity: any) => {
    const lineItemsToUpdate = [{ id: lineItemId, quantity: parseInt(quantity, 10) }];
    setUpdateQuantityInCart(lineItemsToUpdate, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };

  const removeLineItemInCart = (lineItemId: any) => {
    setRemoveLineItemInCart(lineItemId, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };

  const toggleOwnCaskModal = () => {
    setOpenOwnCaskModal(!openOwnCaskModal);
  };
  return (
    <>
      {/* <OwnACaskModal openOwnCaskModal={openOwnCaskModal} toggleOwnCaskModal={toggleOwnCaskModal} setOpenCaskThanksModal={setOpenCaskThanksModal} /> */}
      <ThanksCaskModal openModal={openCaskThanksModal} setOpenModal={setOpenCaskThanksModal} />
      <BuyNowCart
        countryCode={countryCode}
        openModal={openModal}
        toggleCartSidebar={toggleCartSidebar}
        checkout={checkout}
        updateQuantityInCart={updateQuantityInCart}
        removeLineItemInCart={removeLineItemInCart}
      />
      <head>
        <ScoMeta meta={meta ? meta : {}} />
      </head>
      <MainLayout checkout={checkout} toggleCartSidebar={toggleCartSidebar}>
        <div className="new-octave-collection">
          {octavecollectionData && renderOctaveCollectionPage()}
          <OwnACask
            openOwnCaskModal={openOwnCaskModal}
            setOpenCaskThanksModal={setOpenCaskThanksModal}
          />
        </div>
      </MainLayout>
    </>
  );
};

export default CreateYourOwn;
